// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-free-offers-js": () => import("./../../../src/pages/free-offers.js" /* webpackChunkName: "component---src-pages-free-offers-js" */),
  "component---src-pages-how-we-help-js": () => import("./../../../src/pages/how-we-help.js" /* webpackChunkName: "component---src-pages-how-we-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-our-partners-js": () => import("./../../../src/pages/our-partners.js" /* webpackChunkName: "component---src-pages-our-partners-js" */),
  "component---src-pages-story-erin-holland-js": () => import("./../../../src/pages/story-erin-holland.js" /* webpackChunkName: "component---src-pages-story-erin-holland-js" */),
  "component---src-pages-story-john-packham-js": () => import("./../../../src/pages/story-john-packham.js" /* webpackChunkName: "component---src-pages-story-john-packham-js" */),
  "component---src-pages-story-pete-aalbers-js": () => import("./../../../src/pages/story-pete-aalbers.js" /* webpackChunkName: "component---src-pages-story-pete-aalbers-js" */),
  "component---src-pages-story-peter-lehrke-js": () => import("./../../../src/pages/story-peter-lehrke.js" /* webpackChunkName: "component---src-pages-story-peter-lehrke-js" */),
  "component---src-pages-story-sean-leigh-js": () => import("./../../../src/pages/story-sean-leigh.js" /* webpackChunkName: "component---src-pages-story-sean-leigh-js" */),
  "component---src-pages-tidy-software-js": () => import("./../../../src/pages/tidy-software.js" /* webpackChunkName: "component---src-pages-tidy-software-js" */)
}

